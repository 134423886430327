.form-container {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}
    
.login h1 {
    text-align: center;
    margin-left: auto;
    color: var(--text-primary);
    margin: 2rem;
}    

.login button {
    display: inline-block;
    width:100%;
    margin: 0 0.25em 0 0;
    padding: 0.78571429rem 1.5rem;
    background-color: var(--text-primary);
    border: none;
    font-weight: 700;
    transition: opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease;
    border-radius: .28571429rem;
}
    
.login button.disabled {
    opacity: 50%;
}