* {
  box-sizing: border-box;
}

:root {
  /* nord0 - primary text color */
  --text-primary: #2e3440;
  /* nord3 - less visual attention for text*/
  --text-secondary: #4c566a;
  /* nord9 visual attention */
  --text-attention: #5e81ac;
  /* nord6 - main background color */
  --background-primary: var(--nord6);
  --background-highlight: rgb(7, 6, 6);
  /* base colors for ui elements */
  --ui-primary: #d8dee9; /* nord 4*/
  --ui-primary-hover: #e5e9f0; /* nord 5*/

  --error: #bf616a;
  --alert: #bf616a;
  --success: #a3be8c;
  --attention: #ebcb8b;
  --caution: #d08770;
  --title: #81a1c1;
}

body {
  font-family: Lato, Roboto, sans-serif;
  text-rendering: optimizeLegibility;
  background-color: var(--background-primary);
  margin: 0;
  /* height: 100%; */
}
div#root {
  height: 100vh;
}

div.appcontainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  flex-grow: 1;
}

footer {
  padding: 1rem;
  text-align: center;
  background-color: var(--nord4);
  width: 100vw;
}

.footer-notification {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  padding: 2rem;
  border-top: 1px solid var(--nord4);

}

.close {
  float: right;
  /* padding: 1rem; */
  margin-left: auto;
  margin-top: -10px;
  color: grey;
  cursor: pointer;
}

h4 {
  color: var(--text-secondary);
}

h4.dividing {
  text-align: center;
  border-bottom: 1px solid var(--nord4);
} 

.app-top-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 100px 1fr;
  background-color: #4c566a;
  /* background-color: #81a1c1; */
  box-shadow: -5px -5px 15px var(--text-primary);
  padding: 0em;
  align-items: center;
  position: fixed;
  /* font-size: 1.2rem; */
  /* margin-bottom: 2rem; in chrome it pushes the app-top-bar out of the viewport*/
}

.app-top-bar > .right-menu {
  margin-left: auto;
  margin-right: 20px;
  color: white;
}

.app-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: white;
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  line-height: 1.6rem;
  padding: 1rem 2rem;
  /* border-left: 4px solid #81A1C1; */
  align-self: center;
  /* border-right: 1px solid var(--background-primary); */
  background-color: #2e3440;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  margin-left: -1px;
  border-left: 20px solid #2e3440;
}

.logo-title {
  font-weight: 500;
}

.logo-subtitle {
  /* margin-top: 5px; */
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--error);
}

.app-container {
  /* margin-top: 51px; */
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  grid-auto-rows: 300px repeat(50px);
}

.app-content {
  grid-column: 2/3;
  /* grid-row: 2/3; */
}

.main-selectors {
  display: flex;
  flex-direction: row;
  flex: 1fr 1fr 1fr;
  flex-direction: flex-start;
  padding: 1em 1em 2em 1em;
}

.selector {
  padding: 10px;
  color: var(--text-attention);
  cursor: pointer;
}

.active.selector {
  color: var(--text-primary);
  font-weight: bold;
  text-underline-position: below;
  border-bottom: 2px solid var(--text-primary);
}

button {
  font-family: inherit;
  background-color: var(--nord7);
  padding: 0.8em 2rem;
  border: 1px solid var(--nord7);
  color: white;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  /* max-width: 300px; */
}

button.fluid {
  width:100%;
  /* max-width: 300px; */
}

button.secondary {
  background-color: transparent;
  color: var(--nord3);
  border: 1px solid var(--nord3);
  padding-right: 1.5rem;
}

button:hover {
  filter: brightness(110%);
}

button:active {
  filter: brightness(90%);
}

.modal-actions {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.main-actions button {
  margin-top: 20px;
  padding: 0.8rem;
}


.main-actions {
  grid-row: 1/2;
  grid-column: 2/3;
  /* margin:1.2em; */
  padding: 5em 1em 0em 0.5em;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: baseline;
}

.data-view {
  list-style-type: none;
  padding: 0;
  margin: 10px;
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 2rem repeat(3, minmax(15rem, 1fr)) 3rem; */
  /* grid-template-rows: 100px 100px 100px; */
}

.data-item {
  display: grid;
  grid-template-columns: 1em minmax(8rem, 1fr) minmax(10rem, 2fr) minmax(
      15rem,
      3fr
    ) 10rem 6rem;
  margin-bottom: 15px;
  color: var(--text-primary);
  padding: 15px;
  background-color: white;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.data-view-header {
  color: var(--text-secondary);
  padding: 5px;
  background-color: var(--background-primary);
}

.data-item:hover:not(:first-child) {
  box-shadow: #d8dee9 0px 0px 5px 4px;
  /* border: 1px solid #d8dee9;  */
  transition: box-shadow 100ms ease-in-out 0s, border 100ms ease-in-out 0s;
}

.notification {
  background-color: var(--success);
  /* background-color: #2E3440; */
  grid-column: 1/2;
  /* padding:5px; */
  min-width: 1em;
  padding: 0.5em;
}

.alert.notification {
  background-color: var(--caution);
}

.attention.notification {
  background-color: var(--attention);
}

.normal.notification {
  background-color: var(--success);
}

.client-date {
  padding-left: 1em;

  grid-column: 2/3;
}

.client-info {
  grid-column: 3/4;
  /* padding-left: 1em; */
  /* padding:5px; */
}
.client-comment {
  grid-column: 4/5;
  /* padding:5px; */
}
.client-batteries {
  grid-column: 5/6;
}
.actions {
  grid-column: 6/7;
  color: var(--text-attention);
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* padding:5px; */
}

.actions:hover {
  filter: brightness(110%);
}

.actions:active {
  filter: brightness(90%);
}

.mobile {
  color: var(--text-secondary);
}

.clickable {
  cursor: pointer;
}

.alarm-card {
  background-color: white;
  width: 100%;
	box-shadow: rgba(184, 194, 215, 0.9) 0px 4px 6px, rgba(184, 194, 215, 0.1) 0px 5px 7px;
  border-radius: 5px;
}

.alarm-card > .footer {
  align-self: flex-end;
  background-color: var(--nord5);
  padding:1rem 2rem;
  color: var(--text-secondary)
}

.card {
  background-color: white;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
	box-shadow: rgba(184, 194, 215, 0.25) 0px 4px 6px, rgba(184, 194, 215, 0.1) 0px 5px 7px;
  padding: 2rem;
}

.alarm-details {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 2rem;
}

.alarm-details-actions {
  /* margin-top: -5px; */
  float: right;
  display: flex;
  flex-direction: row;
  /* width: 2rem; */
  color: var(--nord8);
  cursor: pointer;
  padding:1rem;
}

.alarm-details-actions > div + div {
  margin-left: 1rem;
  color: var(--nord3);
}


.alarm-details-actions > div + div:hover {
  color: var(--alert);
}

.info {
  margin: 1rem 2rem;
  min-width: 10rem;
}

.info-header {
  color: var(--nord3);
  font-size: 0.9rem;
  margin-bottom: 0;
}

.info-content {
  color: var(--text-primary);
  font-size: 1.5rem;
}

.logentry-actions {
  display: flex;
  flex-direction: row;
  /* width: 2rem; */
  color: var(--nord8);
  cursor: pointer;
  margin-left: auto;
  width: 5rem;
  height: 2rem;
}

.alarm-log h3 {
  padding: 2rem 1rem 0.5rem 1rem;
  /* border-bottom: solid 1px var(--nord4); */
  color: var(--nord10);
  margin-left: -20px;
  display: block;
  font-weight: 400;
}

.alarm-log > header {
  padding: 2rem 1rem 0.5rem 1rem;
  /* border-bottom: solid 1px var(--nord4); */
  color: var(--alert);
  margin-left: -20px;
  display: flex;
  justify-items: start;
  align-items: center;
}
header > h3 + div {
  margin-left: auto;
}

header > button {
  border-left: 5px black;
}

.logentry-card {
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
}

.logentry-card + .logentry-card {
  margin-top: 1rem;
}

.logentry-date {
  font-size: 2rem;
  padding-right: 1rem;
  color: var(--nord9);
}

.logentry-note {
  font-size: 1.2rem;
}

.no-note {
  color: var(--nord2);
  font-style: italic;
}

.logentry-card:hover {
  box-shadow: #d8dee9 0px 0px 5px 4px;
  /* border: 1px solid #d8dee9;  */
  transition: box-shadow 100ms ease-in-out 0s, border 100ms ease-in-out 0s;
}
