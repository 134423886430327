#grid-container {
    display: grid;
    grid-template-rows: 15vh 10em auto;
}

.logo-container {
    grid-row: 2 / 3;
    display: grid;
    grid-template-rows: minmax(2em, 6vw) 1px;
    grid-template-columns: 10vw auto minmax(0, 80vw);
}

.logo-container>h1 {
    align-self: center;
    justify-self: left;
    margin: 0;
    font-size: 4vw;
    padding: 0 0.4em 0 0.4em;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    color: #2B333C;
}

.logo-container>h2 {
    margin: 0;
    align-self: end;
    justify-self: right;
    font-size: 2vw;
    grid-row: 2 / 2;
    grid-column: 3 / 4;
    color: teal;
}

@media (max-width: 600px) {
    .logo-container>h1 {
        font-size: 2em;
    }
}

#logo-line {
    grid-row: 1;
    grid-column: 1;
    background-color: #5A7284;
}

#logo-line2 {
    grid-row: 1;
    grid-column: 3;
    background-color: #5A7284;
}

.login-form-container {
    grid-row: 3 / 4;
    display: grid;
    grid-template-columns: 300px minmax(0, 20vw);
    justify-self: end;
}

input {
    padding: 10px;
    border: 2px solid #ABBEBE;
    border-radius: 5px;
    margin: 10px;
    background-color: #F3F3EE;
    width: 300px;
}

/* button {
    padding: 10px;
    border: 1px solid #2B333C;
    border-radius: 3px;
    margin: 10px;
    background-color: #67DE8B;
    color: white;
    justify-self: right;
    text-transform: uppercase;
    font-weight: bold;
}*/
